@import "~@/styles/variables/variables.scss";

























































































































































.device-status {
	margin-top: 30px;
	margin-left: 5px;
}
.status-modal {
	display: flex;
	.left-device-num {
		width: 160px;
		background: rgb(223, 228, 255);
		border-radius: 12px;
		margin-top: 26px;
		margin-right: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px 0;
		margin-bottom: 2px;
		.icon {
			width: 30px;
			height: 30px;
			border-radius: 12px;
			margin-left: 18px;
		}
		.content {
			align-items: center;
			margin-left: 18px;
			.title {
				margin-top: 5px;
				color: #333;
				white-space: nowrap;
				opacity: 0.7;
			}
			.data {
				margin-top: 12px;
				font-size: 24px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #333;
				line-height: 28px;
				letter-spacing: 1px;
				overflow: hidden;
				white-space: nowrap;
				@media (max-width: 1680px) {
					margin-top: 8px;
					font-size: 20px;
				}
				.unit {
					font-size: 16px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 600;
					line-height: 24px;
				}
			}
		}
	}
}
.items {
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	width: 0;
	.item {
		margin-top: 26px;
		width: 33.3%;
		display: flex;
		padding-right: 20px;
		.icon {
			flex: 0 0 60px;
			height: 60px;
			border-radius: 12px;
		}
		@media (max-width: 1680px) {
			width: 50%;
			.icon {
				flex: 0 0 50px;
				height: 50px;
			}
		}
		.content {
			flex: 1;
			align-items: center;
			margin-left: 26px;
			.title {
				margin-top: 5px;
				color: #333;
				white-space: nowrap;
				opacity: 0.7;
			}
			.data {
				margin-top: 12px;
				font-size: 24px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #333;
				line-height: 28px;
				letter-spacing: 1px;
				overflow: hidden;
				white-space: nowrap;
				@media (max-width: 1680px) {
					margin-top: 8px;
					font-size: 20px;
				}
				.unit {
					font-size: 16px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 600;
					line-height: 24px;
				}
			}
		}
	}
}
