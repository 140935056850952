@import "~@/styles/variables/variables.scss";























































































































.alarming {
	margin-top: 30px;
	margin-left: 5px;
}
.items {
	margin-top: 24px;
	min-height: 300px;
	max-height: calc(100vh - 650px);
	overflow: auto;
	@media (max-width: 1680px) {
		max-height: calc(100vh - 720px);
	}
	.item {
		margin-top: 24px;
		display: flex;
		&:first-of-type {
			margin-top: 0;
		}
		.content {
			width: 45%;
			color: #333;
			.title {
				max-width: 270px;
				font-size: 18px;
				font-weight: 500;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				em {
					font-size: 12px;
					margin-left: 6px;
					background: rgba(76, 107, 255, 0.18);
					border: 1px solid rgba(74, 105, 255, 0.4);
					border-radius: 2px;
					color: #4a69ff;
					display: inline-block;
					padding: 2px;
				}
				@media (max-width: 1680px) {
					font-size: 16px;
				}
			}
			.time {
				margin-top: 8px;
				opacity: 0.7;
			}
		}
		.status {
			width: 20%;
			display: flex;
			align-items: center;
		}
		.btns {
			width: 35%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			.btn {
				@include flex-center;
				cursor: pointer;
				width: 120px;
				height: 32px;
				border-radius: 16px;
				background: #fff;
				border: 1px solid $base-color-theme;
				font-size: 12px;
				color: $base-color-theme;
				&.disabled {
					border: 1px solid rgba(77, 107, 255, 0.5);
					color: rgba(77, 107, 255, 0.5);
					cursor: not-allowed;
				}
				+ .btn {
					margin-left: 10px;
				}
			}
		}
	}
}
