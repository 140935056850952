@import "~@/styles/variables/variables.scss";







































































































.box-alarm {
	margin-top: 30px;
	width: 100%;
	min-height: calc(100% - 194px);
	background: #f2f4fc;
	border-radius: 16px;
	padding: 20px 24px;
	display: flex;
	flex-direction: column;
	.items {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			bottom: 15px;
			width: 1px;
			height: calc(100% - 50px);
			border-left: 1px dashed #9ea5c5;
		}
		.item {
			margin-top: 20px;
			width: 50%;
			display: flex;
			align-items: center;
			cursor: pointer;
			&:nth-of-type(2n + 1) {
				padding-right: 8%;
			}
			&:nth-of-type(2n) {
				padding-left: 8%;
			}
			.icon {
				flex: 0 0 46px;
				height: 46px;
				border-radius: 6px;
			}
			.title {
				flex: 1;
				margin-left: 24px;
				font-size: 18px;
				font-weight: 500;
				color: #333;
				white-space: nowrap;
			}
			.number {
				min-width: 40px;
				font-size: 20px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #333;
				line-height: 24px;
				letter-spacing: 1px;
			}
		}
	}
	.cm-title.chart {
		margin-top: 30px;
	}
	.chart-container {
		flex: 1;
		min-height: 200px;
		max-height: 300px;
		padding-top: 20px;
	}
}
@media (max-width: 1680px) {
	.box-alarm .items {
		.item {
			&:nth-of-type(2n + 1) {
				padding-right: 5%;
			}
			&:nth-of-type(2n) {
				padding-left: 5%;
			}
			.icon {
				flex: 0 0 40px;
				height: 40px;
			}
			.title {
				margin-left: 5%;
				font-size: 16px;
			}
		}
	}
}
