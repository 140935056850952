@import "~@/styles/variables/variables.scss";































































































































::v-deep {
	.cm-title {
		font-size: 20px;
		font-weight: 600;
		color: #333;
		line-height: 30px;
		letter-spacing: 1px;
	}
	.gc-blank-page {
		margin-top: 30px;
		.no-data-img {
			width: initial;
			height: 100px;
		}
	}
}
.home {
	height: 100%;
	min-width: 1126px;
	overflow-y: scroll;
	&-left {
		padding: 55px 21px 0;

		.box-device-type {
			width: 100%;
			height: 194px;
			background: #cbd8ff;
			border-radius: 16px;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 213px;
				height: 121px;
				background: url('~@/assets/imgs/icon/home-oval-up.png');
				background-size: cover;
			}
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				right: 6%;
				width: 213px;
				height: 101px;
				background: url('~@/assets/imgs/icon/home-oval-down.png');
				background-size: cover;
			}
			.icon-device {
				position: absolute;
				top: -45px;
				left: 0;
				width: 180px;
				height: 148px;
			}
			.select {
				position: absolute;
				top: 20px;
				right: 20px;
				@include flex-center;
				width: 142px;
				height: 32px;
				border-radius: 16px;
				background: #728bff;
				color: #fff;
				cursor: pointer;
				.type-list {
					position: absolute;
					z-index: 999;
					top: 37px;
					width: 142px;
					background: #ffffff;
					box-shadow: 0px 6px 23px 0px rgba(0, 0, 0, 0.06);
					border-radius: 4px;
					.item {
						height: 40px;
						line-height: 40px;
						padding-left: 19px;
						color: #4e4e4e;
						&:hover {
							background: rgba(114, 139, 255, 0.5);
							color: #fff;
						}
						&.active {
							background: #728bff;
							color: #fff;
						}
					}
				}
			}
			.device-name {
				position: absolute;
				bottom: 20px;
				left: 30px;
				font-size: 16px;
				color: rgba(71, 77, 89, 0.6);
				line-height: 24px;
				h2 {
					font-size: 24px;
					font-weight: 500;
					color: #474d59;
					line-height: 36px;
					letter-spacing: 3px;
				}
			}
			.refresh-time {
				position: absolute;
				z-index: 99;
				bottom: 20px;
				right: 30px;
				color: #fff;
			}
		}
	}
	&-right {
		height: 100%;
		padding: 55px 25px;
	}
}
